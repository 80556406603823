import SlimSelect from "slim-select";

export const loadSlimSelect = (selector) => {
  document.querySelectorAll(selector).forEach(element => {
    new SlimSelect({
      select: element,
      events: {
        beforeChange: (newVal, oldVal) => {
          const event = new CustomEvent("beforeChange", {
            detail: {newVal, oldVal},
            cancelable: true
          })
          return element.dispatchEvent(event)
        }
      }
    })
  })
}

export const loadSlimSelectAjax = (selector) => {
  document.querySelectorAll(selector).forEach(element => {
    const minimumInputLength = 2;  
    let controller;
    let signal;  
    const initSelection = (select) => {
      if(controller) controller.abort();
      controller = new AbortController();
      signal = controller.signal;
  
      fetch(elem.dataset.url, {
        signal
      }).then(response => response.json().then(data => {
        controller = null;
        const collection = elem.dataset.collection ? (data[elem.dataset.collection] || []) : data;
        const textField = elem.dataset.textField || 'name'
        const results = collection.map((record) => ({ value: record.id, text: record[textField] }));            
        select.setData(results)
        //TODO how to implement pagination
        // pagination: {
        //   more: (data.page * data.per_page) < data.total_count
        // }              

      }));        
    }

    let dataLoaded = false;
    const select = new SlimSelect({
      select: element,
      events: {
        // beforeOpen: () => {
        //   if(!dataLoaded) {
        //     initSelection(select)
        //     dataLoaded = true;
        //   }
        // },  
        beforeChange: (newVal, oldVal) => {
          const event = new CustomEvent("beforeChange", {
            detail: {newVal, oldVal},
            cancelable: true
          })
          return element.dispatchEvent(event)
        },
        search: (search, currentData) => {
          if (search.length < minimumInputLength) {
            return Promise.reject(`Search must be at least ${minimumInputLength} characters`)
          }

          const queryField = elem.dataset.queryField || 'name_cont'

          if(controller) controller.abort();
          controller = new AbortController();
          signal = controller.signal;
          const query = {q: {}}
          query.q[queryField] = search
          return fetch(elem.dataset.url, {
            body: JSON.stringify(query),
            signal
          }).then(response => response.json()).then(data => {
            controller = null;
            const collection = elem.dataset.collection ? (data[elem.dataset.collection] || []) : data;
            const textField = elem.dataset.textField || 'name'
            const options = collection.map((record) => ({ value: record.id, text: record[textField] }));            
            return options.filter(option => {
              return !currentData.some(optionData => optionData.value === option.value)
            });
          })
        }        
      }
    })
    // if(!dataLoaded) {
      initSelection(select)
      dataLoaded = true;
    // }

  })

}

const initSlimSelection = ({
  select, element, signal, urlPath, additionalParams = {},
  formatRecord, responseDataKey
}) => {
  let selectedValues = [element.value]
  if(element.multiple) {
    // return Promise.resolve(); //values are already initialized
    selectedValues = Array.from(element.querySelectorAll('option:checked')).map(option => option.value)
    if(selectedValues.length < 1) return Promise.resolve()
    if(element.querySelector('option:checked').text) {
      return Promise.resolve() //has the required options text
    }
  } else if(!element.value) {
    return Promise.resolve();
  }
  const fullUrl = new URL(location.origin + urlPath);
  const searchParams = fullUrl.searchParams
  // if(element.value) searchParams.append("q[id_eq]", element.value);
  if(element.value) searchParams.append("q[id_in]", selectedValues);
  if(element.dataset.storeId) searchParams.append("store_id", element.dataset.storeId);
  Object.keys(additionalParams).forEach(key => {
    searchParams.append(key, additionalParams[key])
  })

  return fetch(`${fullUrl.pathname}?${searchParams.toString()}`, {
    headers: apiHeaders(),
    signal
  }).then(response => response.json().then(data => {
    // if (element.multiple) {
    //   select.setData(data[responseDataKey].map(formatRecord).map((o) => ({...o, selected: true})));
    // } else {
      const option = formatRecord(data[responseDataKey][0])
      option.selected = true
      select.setData([option]);
    // }
  }));
}

const performSlimSearch = ({
  element, 
  signal, 
  urlPath,
  searchParams,
  formatRecord, 
  responseDataKey,
  currentData 
}) => {
  const fullUrl = new URL(location.origin + urlPath);
  const urlSearchParams = fullUrl.searchParams
  Object.keys(searchParams).forEach(key => {
    urlSearchParams.append(key, searchParams[key])
  })

  if(element.dataset.storeId) urlSearchParams.append("store_id", element.dataset.storeId);

  return fetch(`${fullUrl.pathname}?${urlSearchParams.toString()}`, {
    headers: apiHeaders(),
    signal
  }).then(response => response.json()).then(data => {
    const options = data[responseDataKey].map(formatRecord);
    return options.filter(option => {
      return !currentData.some((optionData) => optionData.value === option.value)
    });
  })

}

const apiHeaders = () => (
  {
    "Authorization": `Bearer ${ Rails.access_token }`,
    "Content-Type": "application/json",
    "Accept": "application/json"
  }  
)

export const applySlimSelectAutocomplete = ({
  element, 
  urlPath,
  formatRecord,
  getInitialParams,
  getSearchParams,
  responseDataKey,
  minimumInputLength = 1
}) => {
  let controller;
  let dataLoaded = false;
  let previousSearch;

  const loadInitialDataConditionally = () => {
    if(!dataLoaded) {
      if(controller) controller.abort("loading...");
      controller = new AbortController();
      const signal = controller.signal;
      const additionalParams = getInitialParams ? getInitialParams() : {}
      initSlimSelection({
        select, 
        element, 
        signal, 
        urlPath,
        formatRecord,
        additionalParams, 
        responseDataKey 
      }).then(() => {
        controller = null;
      })
      dataLoaded = true;
    }
  }

  const select = new SlimSelect({
    select: element,
    events: {
      // beforeOpen: loadInitialDataConditionally,
      search: (search, currentData) => {
        if (search.length < minimumInputLength) {
          return Promise.reject(`Search must be at least ${minimumInputLength} characters`)
        }
        if(previousSearch && previousSearch == search) { //avoid unnecessary api call - bug in slim select
          previousSearch = null;
          return Promise.resolve(currentData); 
        }
        previousSearch = search;
        if(controller) controller.abort("loading...");
        controller = new AbortController();
        const signal = controller.signal;

        return performSlimSearch({
          element, 
          signal, 
          urlPath,
          searchParams: getSearchParams(search),
          formatRecord,
          responseDataKey,
          currentData
        }).finally(() => {
          controller = null;
        })
      }
    }
  });  
  loadInitialDataConditionally();
}