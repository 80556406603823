import "../shared/cloudinary";
import AOS from "aos";
import $ from "../shared/jquery-global";
import "@materializecss/materialize/dist/js/materialize";
import { setupCountryStateCity } from "../shared/address_helper";
import CollapsibleLists from "collapsible-lists/CollapsibleLists.src";
import { Turbo } from "@hotwired/turbo-rails"
Turbo.setFormMode('optin')
import RailsUjs from "@rails/ujs";
import LocalTime from "local-time";
import "../shared/spree/frontend";
import "../shared/frontend";
import "../shared/set-timezone";
import "../shared/set-csrf";
import "select2/dist/js/select2.full";
// import "select2/dist/css/select2";
import "spree/backend2/spree-select2";
import "../shop-admin/product-picker-shop-admin"
import "@oddcamp/cocoon-vanilla-js";

import "../frontend/checkout/payment"
import { initializeSliderCarousel } from "../shared/common";
import { addClass, documentOn, elementsOnEvent, removeClass, toggle } from "../shared/jquery-alternative";
import { setCsrfTokenFromCookie } from "../shared/set-csrf";
// import "../frontend/cart"

// RailsUjs.start(); //not needed anymore and conflicts with turbo
LocalTime.start();

// //import "lazysizes";
// import cloudinary from "cloudinary-core/cloudinary-core-shrinkwrap";
// // import a plugin
// // import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// document.addEventListener('DOMContentLoaded', function() {
//   let cl = cloudinary.Cloudinary.new({ cloud_name: process.env.CLOUDINARY_CLOUD_NAME });
//   cl.responsive();
// });


$(window).scroll(function() {
  var sections = document.querySelectorAll('section');
  sections.forEach(el => {
    var top = $(el).offset().top - 100;
    var bottom = top + $(el).height();
    var scroll = $(window).scrollTop();
    var id = el.id;
    if (scroll > top && scroll < bottom) {
      removeClass('.features-menu a', 'active');
      addClass('.features-menu a[href="#' + id + '"]', 'active');

    }
  });
});
document.addEventListener("turbo:load", function() {
  setCsrfTokenFromCookie();
  RailsUjs.refreshCSRFTokens();
  var cardAlert = document.getElementById("card-alert");
  var closeAlert = document.getElementById("close-alert");
  if (closeAlert) {
    closeAlert.addEventListener("click", () => {
      cardAlert.style.display = "none";
    });
  }

  if (document.querySelector('button.close')) {
    document.querySelectorAll('button.close').forEach((closeAlert) => {
      closeAlert.addEventListener('click', e => {
        e.preventDefault();
        e.currentTarget.parentNode.classList.add('hidden');
      });
    });
  }

  elementsOnEvent('.readmore', "click", function() {
    $(this).prev('p').find(".dots").toggleClass('active');
    $(this).prev('p').find(".moreShow").toggleClass('active');

  });
});


document.addEventListener("DOMContentLoaded", function(event) {
  var currentIndex = 0;
  let imageList = document.querySelectorAll('.image-list li'); //get all the image elements
  if(imageList.length > 0) {
    var totalImages = imageList.length; //get number of image
    var image = document.querySelectorAll('.image-list li')[currentIndex];
    var nextFirstImage = document.querySelectorAll('.image-list li')[currentIndex + 1];
    var nextSecondImage = document.querySelectorAll('.image-list li')[currentIndex + 2];
  
    imageList.forEach(il => il.style.display = "none"); // hide other images
    
    if (window.innerWidth < 767) {
      image.style.display = 'none';
      nextFirstImage.style.display = 'inline-block';
      nextFirstImage.classList.add('active');
      nextSecondImage.style.display = 'none';
    }
    else {
      image.style.display = '';
      nextFirstImage.style.display = 'inline-block';
      nextFirstImage.classList.add('active');
      nextSecondImage.style.display = 'inline-block';
    }  
  }

  //show the current image //
  function showImage(currentIndex) {
    const imageList = document.querySelectorAll('.image-list li');
    var image = imageList[currentIndex];
    var nextFirstImage = imageList[currentIndex + 1];
    var nextSecondImage = imageList[currentIndex + 2];

    imageList.forEach(il => il.style.display = "none"); // hide other images
    
    if (window.innerWidth < 767) {
      image.style.display = 'none';
      nextFirstImage.style.display = 'inline-block';
      nextFirstImage.classList.add('active');
      nextSecondImage.style.display = 'none';
    }
    else {
      image.style.display = 'inline-block';
      nextFirstImage.style.display = 'inline-block';
      nextFirstImage.classList.add('active');
      nextSecondImage.style.display = 'inline-block';
    }
  }

  // next image navigation //
  elementsOnEvent('.slider-navigation-next', 'click', function() {
    currentIndex += 1;
    if (currentIndex > totalImages - 1) {
      currentIndex = 0;
    }
    removeClass('.image-list li', 'active');
    showImage(currentIndex);
  });
  // previous image navigation //
  elementsOnEvent('.slider-navigation-previous', 'click', function() {
    currentIndex -= 1;
    if (currentIndex < 0) {
      currentIndex = totalImages - 1;
    }
    removeClass('.image-list li', 'active');
    showImage(currentIndex);
  });
});



document.addEventListener("turbo:load", function() {
  setupCountryStateCity("#store_store_address_attributes_");
  AOS.init();
});

document.addEventListener("turbo:load", function() {
  let elems = document.querySelectorAll(".sidenav");
  const instances = M.Sidenav.init(elems, {});

  M.updateTextFields();

  if(document.querySelectorAll(".tabs").length > 0) {
    M.Tabs.init(document.querySelectorAll(".tabs"), {});
  }

  elems = document.querySelectorAll(".btnMenuToggle");
  elems.forEach(elem => {
    elem.onclick = function(e) {
      const target = this.getAttribute("data-target");
      console.log(target);
      const elem = document.querySelector(`#${ target }`);
      const instance = M.Sidenav.getInstance(elem);
      if (instance.isOpen) {
        setTimeout(() => instance.close(), 50);
      }
    };
  })

  if(document.querySelectorAll(".products-carousel").length > 0) {
    M.Carousel.init(document.querySelectorAll(".products-carousel"));
  }
  initializeSliderCarousel();
  // M.AutoInit();
});

document.addEventListener("turbo:load", function() {
  //TODO fix CollapsibleLists collapsible conflict with materialzie
  if (
    document.querySelectorAll(".cart-orders .collapsible").length > 0 &&
    !!$(".cart-orders .collapsible").collapsible
  ) {
    $(".cart-orders .collapsible").collapsible({
      onOpen(el) {
        // Callback for Collapsible open
        return el
          .querySelector(".material-icons.cart-arrow-icon")
          .innerHTML = "keyboard_arrow_down";
      },
      onClose(el) {
        // Callback for Collapsible close
        return el
          .querySelector(".material-icons.cart-arrow-icon")
          .innerHTML = "keyboard_arrow_right";
      },
    });
  }

  documentOn("click", "#card-alert a", function() {
    $(this).parents("#card-alert").fadeOut("slow");
  });

  elementsOnEvent(".cat-menu > li a", "click", function() {
    toggle(this.parentNode.querySelector("ul"));
  });
  CollapsibleLists.apply();
});

